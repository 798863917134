import { createApp } from 'vue';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import { createMetaManager } from 'vue-meta';
import App from './App.vue';
import router from './router';
import popupFunction from './plugins/popupFunction';
import ClickOutSide from '@/directives/clickOutside';
import infiniteScroll from '@/directives/infiniteScroll';

import FloatingVue from 'floating-vue';
import 'floating-vue/dist/style.css';
import Vue from 'vue';
import { disableConsoleInProduction } from '@/utils/console';

disableConsoleInProduction();

const app = createApp(App);
app.config.warnHandler = () => {};
const metaManager = createMetaManager();

app.directive('click-outside', ClickOutSide);
app.directive('infinite-scroll', infiniteScroll);

app.provide('$alert', popupFunction.alert);
app.provide('$toast', popupFunction.toast);

const pinia = createPinia();

pinia.use(piniaPluginPersistedstate);

app
  .use(router)
  .use(pinia)
  .use(FloatingVue)
  // .use(store)
  .use(metaManager)
  .mount('#app');
