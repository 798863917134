export const storeData = async (key: string, value: any) => {
  try {
    const stringValue = JSON.stringify(value);
    await localStorage.setItem(key, stringValue);
  } catch (e: any) {
    console.log(e.message);
  }
};
export const getData = async (key: string) => {
  try {
    const value = await localStorage.getItem(key);
    if (value !== null) {
      return JSON.parse(value);
    }
  } catch(e: any) {
    console.log(e.message);
  }
};
export const removeData = async (key:string) => {
  try {
    await localStorage.removeItem(key);
  } catch (e: any) {
    console.log(e.message);
  }
};