export default [
  {
    path: '/instruction/new-register',
    name: 'instructionNewRegister',
    meta: {
      type: 'instruction',
      isLeft: false,
      isHeader: false,
    },
    component: () => import('@/views/instruction/NewRegister.vue'),
  },
];
