<template>
  <header
    :class="{
      'c-header': true,
      'c-header__timeline': type === 'timeline',
    }"
  >
    <div
      class="c-header__title"
      v-if="['home', 'recruitment'].includes(type)"
    >
      <router-link
        :to="'/'"
        class="c-header__logo"
      >
        <!-- <i class="c-icon c-icon--logo"><span class="s-hide">EPOSO</span></i>
        EPOSO -->
        <i class="c-icon c-icon--logo"><span class="s-hide">EPOSO</span></i>
      </router-link>
    </div>
    <div class="c-header__default-wrapper">
      <div class="c-header__project-box">
        <div class="c-header__project-tit">
          <h3
            class="c-header__project-title"
            v-if="type === 'dashboard'"
          >
            {{ $t('component.header', 'dashboard') }}
          </h3>
          <h3
            class="c-header__project-title"
            v-else-if="type === 'timeline'"
          >
            {{ $t('component.header', 'timeline') }}
          </h3>
          <h3
            class="c-header__project-title"
            v-else-if="type === 'calculate'"
          >
            {{ $t('component.header', 'settlement') }}
          </h3>
          <h3
            class="c-header__project-tab"
            v-else-if="type === 'setting'"
          >
            <div>
              {{ $t('component.header', 'settings') }}
            </div>
            <!-- <div>회사 설정</div> -->
          </h3>
          <h3
            class="c-header__project-tab"
            v-else-if="type === 'epic'"
          >
            <div>
              {{ $t('common', 'epic') }}
            </div>
            <!-- <div>회사 설정</div> -->
          </h3>
          <button
            type="button"
            @click="isProject"
            class="c-header__project"
            v-if="['dashboard', 'timeline', 'calculate', 'epic'].includes(type)"
          >
            {{ selectedProjectName }}
          </button>
          <div
            v-if="projectDropbox"
            class="c-header__project-list z-[110]"
          >
            <button
              :style="{ display: 'flex' }"
              type="button"
              v-for="(item, idx) in moduleRole.isClient
                ? projectList.client
                : projectList.professional"
              :key="item?.projectIdx"
              @click="
                item.status === ProjectStatus.ACTIVE ? handleSelectProject(item) : goToSetting(idx)
              "
              :disabled="item.status === ProjectStatus.INACTIVE"
            >
              <span :style="{ marginRight: '7px' }">
                {{ item.name }}
              </span>
              <span v-if="item.status === ProjectStatus.INACTIVE">
                <img
                  class="size-[16px]"
                  src="@/assets/images/icon_warning_yellow.svg"
                />
              </span>
            </button>
          </div>
        </div>
      </div>
      <div
        v-if="profile.name"
        class="c-header__body"
      >
        <!-- <div class="c-header__time-box">
          <i class="c-icon c-icon--time"></i>
          <span class="c-header__time-txt">
            <strong>1300</strong>
            시간
          </span>
        </div> -->
        <div class="c-header__drop-area">
          <button
            type="button"
            class="c-header__user-btn"
            @click="handleDropbox"
          >
            <DefaultProfileImage
              :profileForm="profile"
              :ableShowProfile="false"
              @click="handleDropbox"
            />
            <span class="c-header__user-name">{{ profile.nickname }}</span>
          </button>
          <div
            v-if="isDropbox"
            class="c-header__dropbox"
            ref="dropdownRef"
          >
            <!-- v-click-outside="() => (isDropbox = false)" -->
            <button
              type="button"
              class="c-header__drop-btn"
              @click="handleProfile"
            >
              {{ $t('component.header', 'profile') }}
            </button>
            <button
              type="button"
              class="c-header__drop-btn"
              @click="method.handleLogOut"
            >
              {{ $t('component.header', 'logout') }}
            </button>
          </div>
        </div>
        <span
          class="c-header__user-tag"
          :class="moduleRole.isClient ? 'customer' : 'expert'"
          @click="handleChangeRole"
          >{{ renderLabelRole }}

          <i
            :class="['c-icon', moduleRole.isClient ? 'c-icon--change__green' : 'c-icon--change']"
          ></i>
        </span>
      </div>
      <div
        v-else
        class="c-header__body"
      >
        <span class="c-header__desc">{{ $t('component.header', 'noLoggedIn') }}</span>
        <BaseButton
          size="small"
          @click="$router.push('/login')"
        >
          {{ $t('component.header', 'loginJoin') }}
        </BaseButton>
      </div>
    </div>
    <div
      class="c-header__link-wrapper"
      v-if="type === 'timeline'"
    >
      <div
        class="c-header__project-link"
        v-if="type === 'timeline'"
      >
        <div
          v-for="url in urlList"
          class="c-header__project-fixed cursor-pointer"
          :key="url.linkIdx"
          @click="openUrl(url.url)"
        >
          <!-- <i class="c-icon c-icon--ms-office"></i> -->
          <img
            v-if="url.metaImage"
            :src="url.metaImage"
            class="c-metaImage"
            alt="Icon"
            @error="onErrorImage"
          />
          <img
            v-else
            src="@/assets/images/icon_link.svg"
            class="c-metaImage"
            alt="Icon"
          />
          <p>{{ url.metaTitle }}</p>
          <button
            v-if="!moduleRole.isClient"
            type="button"
            class="c-header__delete"
            @click.stop.prevent="method.removeLink(url.linkIdx)"
          >
            <i class="c-icon c-icon--close">
              <span class="s-hide">{{ $t('common', 'delete') }}</span>
            </i>
          </button>
        </div>
        <BaseButton
          v-if="!moduleRole.isClient"
          :icon="true"
          size="xxsmall"
          color="outline"
          @click="popupLink"
        >
          <i class="c-icon c-icon--plus-gray">
            <span class="s-hide">{{ $t('common', 'delete') }}</span>
          </i>
          {{ $t('common', 'link') }}
        </BaseButton>
        <BasePopup
          :title="$t('component.header', 'addLink')"
          v-model="isLink"
          :confirmText="$t('common', 'save')"
          :onConfirm="addLink"
        >
          <div class="link-pop">
            <div class="link-pop__label">{{ $t('common', 'link') }}</div>
            <BaseInput
              v-model="newLink"
              placeholder="www.eposo.ai"
              @keyup.enter="
                () => {
                  isLink = false;
                  addLink();
                }
              "
            />
          </div>
        </BasePopup>
      </div>
    </div>
  </header>
</template>

<script setup lang="ts">
import { ref, computed, watch, inject, onBeforeUnmount } from 'vue';
import { useRoute } from 'vue-router';
import router from '@/router';
import isEmpty from 'lodash/isEmpty';

import { Http } from '@/shared/api/api';

import BasePopup from '@/components/BasePopup.vue';
import BaseInput from '@/components/BaseInput.vue';
import BaseButton from '@/components/BaseButton.vue';
import DefaultProfileImage from '@/components/DefaultProfileImage.vue';

import { profileStore } from '@/stores/profile';
import { userStore } from '@/stores/authStore';
import { projectStore } from '@/stores/project';
import { roleStore } from '@/stores/role';
import { projectListStore } from '@/stores/projectList';

import { FormProfile } from '@/shared/types/Profile';
import { Link } from '@/shared/types/Link';
import { Project, ProjectStatus } from '@/shared/types/Project';

import { ensureHttps } from '@/utils';
import { openUrl } from '@/utils/url';

import { getMemberProjectInfo } from '@/api/timeline';

import { $t } from '@/plugins/translation';

const props = defineProps({
  type: {
    type: String,
    default: 'home',
  },
});

const route = useRoute();

const authStore = userStore();

const moduleRole = roleStore();
const selectedProject = projectStore();
const projectList = projectListStore();
// const dropboxRef = ref(null);

const $toast = inject('$toast') as Function;

const dropdownRef = ref<HTMLDivElement | null>(null);
const isDropbox = ref(false);
const projectDropbox = ref(false);

const profileInfo = profileStore();
const profile = computed(() => profileInfo.profile as FormProfile);
// const projectList = ref<ProjectList[]>([]);
const urlList = ref<Link[]>([]);

const selectedProjectName = computed(() => selectedProject.selectedProject?.name);

const onErrorImage = (event: Event): void => {
  const target = event.target as HTMLImageElement;
  target.src = '@/assets/images/icon_link.svg';
};

const handleDropbox = () => {
  !isDropbox.value ? handleOpen() : handleClose();
};
const handleOpen = () => {
  isDropbox.value = true;
  window.addEventListener('mousedown', handleOutside);
};
const handleClose = () => {
  isDropbox.value = false;
  window.removeEventListener('mousedown', handleOutside);
};

const handleOutside = (e: MouseEvent) => {
  if (dropdownRef.value && !dropdownRef.value.contains(e.target as Node)) {
    handleClose();
  }
};

const handleProfile = () => {
  isDropbox.value = !isDropbox.value;
  router.push('/profile');
};

const isProject = async () => {
  projectDropbox.value = !projectDropbox.value;
};

const handleGetMemberInfo = async () => {
  try {
    const response = await getMemberProjectInfo({ projectIdx: selectedProject.selectedProjectId });
    if (response?.status !== 200) return;
    moduleRole.userRoles = response.data?.roles || [];
    moduleRole.currentRole = moduleRole.userRoles[0]?.role?.name;
    selectedProject.selfProjectIdx = response.data?.memberInProjectIdx;
  } catch (error) {
    console.error(error);
  }
};

const handleSelectProject = async (project: any) => {
  projectDropbox.value = false;
  selectedProject.selectedProject = project;

  let newPath = '';

  switch (props.type) {
    case 'calculate':
      newPath = `/calculate/expert/${project.projectIdx}`;
      break;
    case 'dashboard':
      newPath = `/dashboard/${project.projectIdx}`;
      break;
    case 'timeline':
      newPath = `/timeline/${project.projectIdx}`;
      break;
    case 'setting':
      newPath = `/setting/${project.projectIdx}`;
      break;
    case 'epic':
      newPath = `/epic/${project.projectIdx}`;
      break;
    default:
      break;
  }

  router.push(newPath);
};

const goToSetting = (idx: number) => {
  router.push(`/setting?idx=${idx}`);
};

const renderLabelRole = computed(() => {
  return moduleRole.isClient ? $t('common', 'client') : $t('common', 'professional');
});

const isLink = ref(false);
const newLink = ref('');
const popupLink = () => {
  isLink.value = !isLink.value;
};
const addLink = async () => {
  if (newLink.value) {
    method.createLinks();
    newLink.value = '';
  }
};

const handleGetProjectList = async (isClient: boolean) => {
  const projectListTmp = isClient ? projectList.client : projectList.professional;
  if (!route.query.selectedStory && !route.query.selectedMilestone)
    selectedProject.isInAProject = !isEmpty(projectListTmp);
  else selectedProject.isInAProject = true;

  if (!selectedProject.isInAProject) return router.push({ name: 'No project' });
  const listCurrentProjectIds = projectListTmp.map((project: Project) => project?.projectIdx);

  if (route.params.id && Number(route.params.id) !== selectedProject.selectedProjectId) {
    const foundProject = projectList.allProjects.find(
      (project: Project) => project.projectIdx === Number(route.params.id),
    );
    if (foundProject) {
      selectedProject.selectedProject = foundProject;
      return;
    } else return router.push({ name: 'Permission denied' });
  }

  if (
    !selectedProject.selectedProjectId ||
    !listCurrentProjectIds.includes(selectedProject.selectedProjectId as number)
  )
    selectedProject.selectedProject = projectListTmp[0];

  router.replace({ params: { id: selectedProject.selectedProjectId }, query: { ...route.query } });

  await handleGetMemberInfo();
};

const handleChangeRole = async () => {
  moduleRole.isClient = !moduleRole.isClient;
  $toast({
    icon: 'green',
    desc: moduleRole.isClient
      ? $t('component.header', 'convertClient')
      : $t('component.header', 'convertProfessional'),
  });
  if (route.name === 'home') return;
  if (!['setting', 'No project', 'Permission denied'].includes(route.name as string))
    handleGetProjectList(moduleRole.isClient);
  router.push('/');
};

const method = {
  handleLogOut: async () => {
    isDropbox.value = !isDropbox.value;
    await Http.api()
      .post('/auth/logout', {})
      .then(() => {
        authStore.reset();
        moduleRole.reset();
        // selectedProject.reset();
        profileInfo.reset();
        projectList.reset();
        router.push('/');
      })
      .catch((err) => {
        console.log(err);
      });
  },
  getLinks: async () => {
    await Http.api()
      .get(`/link`, { projectIdx: selectedProject.selectedProjectId })
      .then((res) => {
        if (res && res.data) {
          urlList.value = res.data;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
  createLinks: async () => {
    await Http.api()
      .post(`/link`, {
        url: ensureHttps(newLink.value),
        projectIdx: selectedProject.selectedProjectId,
      })
      .then((res) => {
        if (res && res.data) {
          method.getLinks();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },
  removeLink: async (linkIdx: number) => {
    await Http.api()
      .delete(`/link/${linkIdx}`)
      .then((res) => {
        console.log(res);
        method.getLinks();
      })
      .catch((err) => {
        console.log(err);
      });
  },
  getProjectList: async () => {
    if (!projectList.isFetchProject)
      await Http.api()
        .get('/project/list-of-member')
        .then((res) => {
          if (!res || !res.data) return;
          else projectList.allProjects = res.data;
          projectList.isFetchProject = true;
        })
        .catch((err) => {
          console.log(err);
        });
  },
};

watch(
  () => route.path,
  async () => {
    projectDropbox.value = false;
    if (selectedProject.selectedProjectId && route.name === 'Timeline') await method.getLinks();
    if (
      !['setting', 'No project', 'Permission denied', 'home', 'Profile'].includes(
        route.name as string,
      ) &&
      authStore.login
    ) {
      method.getProjectList().then(() => {
        handleGetProjectList(moduleRole.isClient);
      });
    }
  },
  { immediate: true },
);

onBeforeUnmount(() => {
  window.removeEventListener('mousedown', handleOutside);
});
</script>

<style lang="scss">
.c-header {
  $this: '.c-header';
  display: flex;
  justify-content: space-between;
  align-items: center;
  // padding: 1.25rem 1rem 1.25rem 2rem;
  padding: 1.6rem 3.2rem;
  background-color: var(--white);
  border-bottom: 1px solid var(--gray-30);
  // height: 6.4rem;
  height: -moz-fit-content;
  height: fit-content;
  // &__sub {
  //   align-items: center;
  //   &--txt{
  //     display: block;
  //     margin-right: var(--space-32);
  //     font-size: var(--font-20);
  //     font-weight: var(--font-bold);
  //   }
  //   &--project {
  //     position: relative;
  //     padding-right: var(--space-32);
  //     line-height: 2.4rem;
  //     font-size: var(--font-16);
  //     &:after {
  //       content: '';
  //       position: absolute;
  //       right: 0;
  //       width: 2.4rem;
  //       height: 2.4rem;
  //       background: url(@/assets/images/icon_dropdown_green.svg);
  //     }
  //   }
  // }
  &__timeline {
    height: fit-content;
    display: flex;
    flex-direction: column;
  }
  &__default-wrapper {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  &__link-wrapper {
    display: flex;
    align-items: start;
    width: 100%;
  }
  &__body {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  &__body_timeline {
    display: flex;
    flex-direction: row;
    height: 34px;
    /* margin-top: 1rem; */
    /* margin-right: 1rem; */
    gap: 1rem;
    align-items: start;
  }
  &__logo {
    display: flex;
    align-items: center;
    font-weight: var(--font-extrabold);
    font-size: var(--font-24);
    color: var(--main-30);
  }

  &__desc {
    //margin-right: 1rem;
    font-size: var(--font-14);
    font-weight: var(--font-medium);
  }
  &__time-box {
    display: flex;
    align-items: center;
    padding: 5px 0;
    gap: 0.6rem;
  }
  &__time-txt {
    font-size: var(--font-14);
    strong {
      margin-right: 0.2rem;
      font-weight: var(--font-bold);
    }
  }
  &__user-btn {
    display: flex;
    align-items: center;
  }
  &__user-name {
    font-size: var(--font-14);
    font-weight: var(--font-medium);
    margin-left: 0.4rem;
  }
  &__user-tag {
    // width: 8rem;
    height: 3.2rem;
    padding: 0.5rem 1.2rem;
    border-radius: var(--radius-16);
    background: var(--main-20);
    font-size: var(--font-12);
    font-weight: var(--font-bold);
    color: var(--white);
    display: flex;
    gap: 0.4rem;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &.expert {
      background: var(--main-20);
    }

    &.customer {
      background: var(--white);
      color: var(--black);
      border: 1px solid var(--main-20);
    }
  }
  &__drop-area {
    position: relative;
    padding: 5px 0;
  }
  &__dropbox {
    border: 1px solid var(--main-10);
    border-radius: 0.4rem;
    display: flex;
    flex-flow: column;
    position: absolute;
    top: calc(100% + 1rem);
    right: 0;
    width: 9.2rem;
    font-size: var(--font-14);
    z-index: 999;
    box-shadow: 4px 2px 8px 0px rgba(0, 0, 0, 0.25);
  }
  &__drop-btn {
    padding: 0.8rem 1.2rem;
    background-color: var(--white);
    border-radius: 0.4rem;
    &:hover {
      background-color: var(--main-90);
    }
  }
  &__project-box {
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
  }
  &__project-tit {
    display: flex;
    // margin-top: 0.25rem;
    > h3 {
      font-size: var(--font-22);
      font-weight: bold;
    }
  }
  /* &__project-title {
    // width: 100px;
  } */
  &__project-tab {
    height: 100%;
    display: flex;
    gap: 4rem;
    cursor: pointer;
  }
  &__project {
    padding-right: 3rem;
    margin-left: 32px;
    position: relative;
    font-size: var(--font-18);
    &::after {
      content: '';
      width: 2.4rem;
      height: 2.4rem;
      background: url(@/assets/images/icon_dropdown_green.svg) center / contain;
      position: absolute;
      right: 0;
      top: calc(50% - 1.2rem);
    }
  }
  &__project-link {
    display: flex;
    width: 100%;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 1.5rem;
    gap: 0.6rem;
    p {
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 250px;
    }
  }
  &__project-fixed {
    background-color: #f2f2f2;
    border-radius: 0.4rem;
    display: inline-block;
    display: flex;
    align-items: center;
    padding: 5px;
    font-size: var(--font-14);
    gap: 0.4rem;
    .c-metaImage {
      width: 20px;
      height: 20px;
      padding: 2px;
    }
  }
  &__delete {
    margin-left: 4px;
    margin-right: 1px;
    .c-icon {
      width: 1rem;
      height: 1rem;
    }
  }
  &__project-list {
    border: 1px solid var(--main-10);
    display: flex;
    flex-flow: column;
    box-shadow: 0 0.4rem 2rem rgba(0, 0, 0, 0.15);
    border-radius: 0 0.4rem 0.4rem 0;
    overflow: hidden;
    position: absolute;
    top: 6rem;
    left: 8rem;
    width: 35rem;
    height: calc(100vh - 6rem);
    z-index: 10;
    background-color: var(--white);
    overflow: auto;
    > button {
      border-bottom: 1px solid var(--gray-50);
      width: 100%;
      padding: 1.9rem 2.7rem;
      background-color: var(--white);
      text-align: left;
      font-size: var(--font-14);
      font-weight: 500;
      &:hover {
        background-color: var(--main-30);
        color: var(--white);
      }
      &:disabled {
        color: var(--gray-20);
      }
    }
  }
}
</style>
