<template>
  <div
    :class="`
      c-form c-form--${state} 
      ${icon.length > 0 && !disabled && 'c-form--icon'} 
      c-form--${size} c-form--${error ? 'error' : 'default'} 
      c-form--${type ? `${type}` : 'default'}
      ${align && `c-form--${align}`}
      ${align && `c-form--${align}`} 
    `"
  >
    <i
      v-if="type == 'search'"
      class="c-icon c-icon--search"
    >
      <span class="s-hide">search</span>
    </i>
    <input
      :type="type"
      :class="`
        c-form__input
      `"
      :placeholder="placeholder"
      :value="modelValue"
      :error="error"
      :disabled="disabled || isLoading"
      :maxLength="maxLength"
      :onchange="onChange"
      @keydown="onlyNumber"
      @input="updateValue"
      @focusin="$emit('focusin', $event)"
      @focusout="$emit('focusout', $event)"
      v-on:keyup.enter="onEnterKeyUp"
      :tabIndex="tabIndex"
      :ref="inputRef"
    />
    <button
      type="button"
      @click="iconbutton"
    >
      <template v-if="icon.length > 0 && !disabled">
        <img
          v-if="isLoading"
          class="size-[22px] absolute top-[50%] translate-y-[-50%] right-[10px]"
          src="@/assets/images/icon_loading.svg"
        />

        <i
          v-else
          :class="`c-form__icon c-form__icon--${icon}`"
        >
          <span class="s-hide">{{ icon }}</span>
        </i>
      </template>
    </button>
    <span class="c-form__msg">{{ message }}</span>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  type: {
    type: String,
    default: 'text',
  },
  modelValue: {
    type: [String, Number],
  },
  updateValue: {
    type: String,
  },
  size: {
    type: String,
    default: 'medium',
  },
  state: {
    type: String,
    default: 'default',
  },
  message: {
    type: String,
    default: '',
  },
  placeholder: {
    type: String,
  },
  icon: {
    type: String,
    default: '',
  },
  error: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  maxLength: {
    type: Number,
  },
  onlyNumber: {
    type: Boolean,
    default: false,
  },
  align: {
    type: String,
    default: '',
  },
  onChange: Function,
  onClick: Function,
  onEnterKeyUp: Function,
  onTabKeyUp: Function,
  inputRef: {
    type: Object,
    default: () => {},
  },
  tabIndex: {
    type: Number,
    default: 1,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:modelValue', 'focusin', 'focusout']);

const updateValue = (e: Event) => {
  emit('update:modelValue', (e.target as HTMLInputElement).value);
};
const iconbutton = async () => {
  if (props.onClick) {
    try {
      const result = props.onClick();
      if (result instanceof Promise) {
        await result;
      }
    } catch (error) {
      console.error('에러 발생:', error);
    }
  }
};
const onlyNumber = (e: KeyboardEvent) => {
  if (!props.onlyNumber) return;

  const key = e.key;
  const isNumber = key >= '0' && key <= '9';
  const isControlKey =
    key === 'Backspace' || key === 'Delete' || key === 'ArrowLeft' || key === 'ArrowRight';

  if (!isNumber && !isControlKey && key !== '.') {
    e.preventDefault();
  }
  // if (!props.onlyNumber) return;
  // let keyCode = e.keyCode ? e.keyCode : e.which;
  // // 8 is backspace
  // if (keyCode === 8) return;
  // if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) {
  //   // 46 is dot
  //   e.preventDefault();
  // }
};
</script>

<style lang="scss">
input:disabled {
  color: var(--gray-10);
  background-color: var(--gray-50);
}
</style>
