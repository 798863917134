import { Http } from '@/shared/api/api';

enum TimelineEndpoints {
  TIMELINE = '/milestone/timeline',
  STORIES = '/milestone/stories',
  MILESTONE = '/milestone',
  STORY = '/story',
  ASSIGNEE = '/assignee',
  TIMEKEEPING = '/timekeeping',
  EPIC_PROJECT = '/epic/all-of-project',
  MEMBER_PROJECT = '/member-in-project',
  LINK = '/link',
}

interface Params {
  [key: string]: any;
}

interface Data {
  [key: string]: any;
}

export const getTimeline = async (params: Params) => {
  try {
    const response = await Http.api().get(TimelineEndpoints.TIMELINE, params);
    return response;
  } catch (error) {
    console.error('Error fetching timeline:', error);
    throw error;
  }
};

export const createMileStone = async (data: Data) => {
  try {
    const response = await Http.api().post(TimelineEndpoints.MILESTONE, data);
    return response;
  } catch (error) {
    console.error(`Error create milestone:`, error);
    throw error;
  }
};

export const getStories = async (params: Params) => {
  try {
    const response = await Http.api().get(TimelineEndpoints.STORIES, params);
    return response;
  } catch (error) {
    console.error('Error fetching stories:', error);
    throw error;
  }
};

export const updateMileStone = async (idMilestone: number, data: Data) => {
  try {
    const response = await Http.api().patch(`${TimelineEndpoints.MILESTONE}/${idMilestone}`, data);
    return response;
  } catch (error) {
    console.error(`Error updating milestone ${idMilestone}:`, error);
    throw error;
  }
};
export const updateMileStoneOrder = async (data: Data) => {
  try {
    const response = await Http.api().patch(`${TimelineEndpoints.MILESTONE}/order`, data);
    return response;
  } catch (error) {
    console.error(`Error updating milestone order:`, error);
    throw error;
  }
};
export const updateMileStoneApprovalStatus = async (idMilestone: number, data: Data) => {
  try {
    const response = await Http.api().patch(
      `${TimelineEndpoints.MILESTONE}/approved/${idMilestone}`,
      data,
    );
    return response;
  } catch (error) {
    console.error(`Error updating milestone order:`, error);
    throw error;
  }
};

export const deleteMileStone = async (idMilestone: number) => {
  try {
    const response = await Http.api().delete(`${TimelineEndpoints.MILESTONE}/${idMilestone}`);
    return response;
  } catch (error) {
    console.error(`Error deleting milestone ${idMilestone}:`, error);
    throw error;
  }
};

export const getMilestone = async (params: Params) => {
  try {
    const response = await Http.api().get(
      `${TimelineEndpoints.MILESTONE}/${params.milestoneIdx}`,
      {},
    );
    return response;
  } catch (error) {
    console.error('Error fetching story:', error);
    throw error;
  }
};
export const getStory = async (params: Params) => {
  try {
    const response = await Http.api().get(`${TimelineEndpoints.STORY}/${params.storyIdx}`, {});
    return response;
  } catch (error) {
    console.error('Error fetching story:', error);
    throw error;
  }
};
export const getAwaitingZoneStory = async (params: Params) => {
  try {
    const response = await Http.api().get(`${TimelineEndpoints.STORY}/awaiting-zone`, params);
    return response;
  } catch (error) {
    console.error('Error fetching story:', error);
    throw error;
  }
};
export const getStoryNoEpic = async (projectIdx: number, params?: Params) => {
  try {
    const response = await Http.api().get(
      `${TimelineEndpoints.STORY}/no-epic/${projectIdx}`,
      params,
    );
    return response;
  } catch (error) {
    console.error('Error fetching story:', error);
    throw error;
  }
};
export const getStoryByEpic = async (epicIdx: number, params?: Params) => {
  try {
    const response = await Http.api().get(`${TimelineEndpoints.STORY}/by-epic/${epicIdx}`, params);
    return response;
  } catch (error) {
    console.error('Error fetching story:', error);
    throw error;
  }
};
export const createStory = async (data: Data) => {
  try {
    const response = await Http.api().post(TimelineEndpoints.STORY, data);
    return response;
  } catch (error) {
    console.error(`Error create story:`, error);
    throw error;
  }
};
export const updateStory = async (idStory: number, data: Data) => {
  try {
    console.log(data);
    const response = await Http.api().patch(`${TimelineEndpoints.STORY}/${idStory}`, data);
    return response;
  } catch (error) {
    console.error(`Error updating story ${idStory}:`, error);
    throw error;
  }
};
export const updateStoryOrder = async (data: Data) => {
  try {
    console.log(data);
    const response = await Http.api().patch(`${TimelineEndpoints.STORY}/order`, data);
    return response;
  } catch (error) {
    console.error(`Error updating story order:`, error);
    throw error;
  }
};
export const deleteStory = async (idStory: number) => {
  try {
    const response = await Http.api().delete(`${TimelineEndpoints.STORY}/${idStory}`);
    return response;
  } catch (error) {
    console.error(`Error deleting story ${idStory}:`, error);
    throw error;
  }
};

export const updateAssignee = async (idAssignee: number, data: Data) => {
  try {
    const response = await Http.api().patch(`${TimelineEndpoints.ASSIGNEE}/${idAssignee}`, data);
    return response;
  } catch (error) {
    console.error(`Error updating assignee ${idAssignee}:`, error);
    throw error;
  }
};

export const createTimekeeping = async (data: Data) => {
  try {
    const response = await Http.api().post(TimelineEndpoints.TIMEKEEPING, data);
    return response;
  } catch (error) {
    console.error('Error create timekeeping:', error);
    throw error;
  }
};
export const deleteTimekeeping = async (data: Data) => {
  try {
    const response = await Http.api().delete(`${TimelineEndpoints.TIMEKEEPING}/bulk`, data);
    return response;
  } catch (error) {
    console.error('Error delete timekeeping:', error);
    throw error;
  }
};

export const getMemberProject = async (params: Params) => {
  try {
    const response = await Http.api().get(TimelineEndpoints.MEMBER_PROJECT, params);
    return response;
  } catch (error) {
    console.error('Error fetching member project:', error);
    throw error;
  }
};
export const getMemberProjectInfo = async (params: Params) => {
  try {
    const response = await Http.api().get(`${TimelineEndpoints.MEMBER_PROJECT}/info`, params);
    return response;
  } catch (error) {
    console.error('Error fetching member project:', error);
    throw error;
  }
};
export const getEpicProject = async (params: Params) => {
  try {
    const response = await Http.api().get(TimelineEndpoints.EPIC_PROJECT, params);
    return response;
  } catch (error) {
    console.error('Error fetching epic project:', error);
    throw error;
  }
};

export const getLink = async (params: Params) => {
  try {
    const response = await Http.api().get(TimelineEndpoints.LINK, params);
    return response;
  } catch (error) {
    console.error('Error fetching member project:', error);
    throw error;
  }
};

export const createLink = async (data: Data) => {
  try {
    const response = await Http.api().post(TimelineEndpoints.LINK, data);
    return response;
  } catch (error) {
    console.error('Error fetching member project:', error);
    throw error;
  }
};
export const removeLink = async (linkIdx: number) => {
  try {
    const response = await Http.api().delete(`${TimelineEndpoints.LINK}/${linkIdx}`);
    return response;
  } catch (error) {
    console.error('Error fetching member project:', error);
    throw error;
  }
};
