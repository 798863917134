import { isEmpty } from 'lodash';
import { FormProfile } from '@/shared/types/Profile';
import { Coupon } from '@/shared/types/Coupon';
import { defineStore } from 'pinia';
import { reactive, ref, computed, watch } from 'vue';
import { createProfileImg } from '@/utils/file';
import { Http } from '@/shared/api/api';

const initialProfile = {
  memberIdx: 0,
  email: '',
  name: '',
  nickname: '',
  birth: '',
  phone: '',
  nationality: '',
  gender: '',
  photo: '',
  colorCode: '',
  job: '',
  isFree: '',
  company: '',
  hopePrice: '',
  career: [],
  profileFileIdx: null,
  projectIdx: 0,
};
export const profileStore = defineStore('profile', () => {
  const profileImgFile = ref<any>(null);
  const profileImg = ref<string>('');
  const profile = ref<FormProfile>({
    memberIdx: 0,
    email: '',
    name: '',
    nickname: '',
    birth: '',
    phone: '',
    nationality: '',
    gender: '',
    photo: '',
    colorCode: '',
    job: '',
    isFree: '',
    company: '',
    hopePrice: '',
    career: [],
    profileFileIdx: 0,
    projectIdx: 1,
    coupons: [],
  });
  const reset = () => {
    profile.value = initialProfile;
    profileImgFile.value = null;
    profileImg.value = '';
  };
  const setProjectIdx = (projectIdx: number) => {
    profile.value.projectIdx = projectIdx;
  };

  const getProfileImg = (index: number) => {
    Http.api()
      .get(`/file?fileIdx=${index}`, {}, 'blob')
      .then((res) => {
        profileImgFile.value = res.data;
        profileImg.value = createProfileImg(profileImgFile.value);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getUserProfile = async () => {
    await Http.api()
      .get('/member')
      .then(async (res) => {
        if (res && res.status == 200) {
          profile.value = {
            ...res.data,
            coupons: res.data.coupons ? res.data.coupons : [],
          };
          if (profile.value.profileFileIdx) await getProfileImg(profile.value.profileFileIdx);
        }
      })
      .catch((err) => {
        console.log('err', err);
      });
  };
  return { profile, reset, setProjectIdx, getUserProfile, profileImgFile, profileImg };
});
