import { EN } from '@/strings/en';

export interface Project {
  companyInProject: string | null;
  createdAt: string;
  createdByIdx: number;
  delYn: string;
  deletedAt: string | null;
  deletedByIdx: number | null;
  endDt: string;
  name: string;
  projectIdx: number;
  startDt: string;
  updatedAt: string;
  updatedByIdx: number;
  isClient: boolean;
  isAdmin: boolean;
  sheets: number;
  totalMember: number;
  status: ProjectStatus;
}
export interface ProjectList {
  projectIdx: number;
  name: string;
  startDt?: string;
  endDt?: string;
  // companyInProject?: string
  // createdAt: string
  // updatedAt: string
  // deletedAt?: string
  // createdByIdx: number
  // updatedByIdx: number
  // deletedByIdx?: number
  // delYn: 'Y' | 'N'
}
export interface Role {
  roleIdx: number;
  name: string;
  description: string;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
  createdByIdx?: number;
  updatedByIdx?: number;
  deletedByIdx?: number;
  delYn: 'Y' | 'N';
}

export interface RoleOfMember {
  roleIdx: number;
  role: Role;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
  createdByIdx?: number;
  updatedByIdx?: number;
  deletedByIdx?: number;
  delYn: 'Y' | 'N';
}
export interface Member {
  memberInProjectIdx: number;
  projectIdx: string;
  memberIdx?: string;
  position?: string;
  charge?: string;
  depth1Idx?: number;
  depth2s: Member[];
  role: RoleOfMember[];
  delYn: 'Y' | 'N';
  member: [];
}

export interface Epic {
  epicIdx?: number;
  title: string;
  weight: number;
  projectIdx: number;
  createdAt?: string;
  updatedAt?: string;
  createdByIdx?: number;
  updatedByIdx?: number;
  stories?: [];
}

export enum ProjectStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum Popup {
  CARD_POP = 'cardPop',
  PAY_POP = 'payPop',
  SUBSCRIBE_POP = 'subscribePop',
  SUB_CHANGE_POP = 'subChangePop',
  REFUND_POP = 'refundPop',
  COUPON_POP = 'couponPop',
}
