export default [
  {
    path: '/epic/:id',
    name: 'epic',
    meta: {
      type: 'epic',
    },
    component: () => import('@/views/epic/Epic.vue'),
  },
];
