<template>
  <metainfo />
  <LeftMenu v-if="isLeft" />
  <div :class="`wrap ${!isHeader && !isLeft && 'wrap--expand'}`">
    <Header
      v-if="isHeader"
      :type="type"
    />
    <div class="c-seo s-hide">
      <img
        src="@/assets/images/SEO_logo.svg"
        alt="SEO Meta Image logo"
      />
    </div>
    <div
      class="content"
      id="content"
    >
      <RouterView :key="computedKey"></RouterView>
    </div>
  </div>
  <SubscriptionWarningBar
    v-if="isShowWarningSubscriptionBar"
    @close="showWarning = false"
  />
  <div class="c-toast-box">
    <Toast
      v-for="(item, index) in isToast"
      :key="index"
      :isToast="isToast[index].text.length > 0"
      :icon="isToast[index].icon"
      :close="isToast[index].close"
    >
      {{ isToast[index].text }}
    </Toast>
  </div>
</template>

<script setup lang="ts">
import { ref, watchEffect, computed, watch, onMounted } from 'vue';
import Header from './components/Header.vue';
import LeftMenu from './components/LeftMenu.vue';
import SubscriptionWarningBar from './components/subscription/SubscriptionWarningBar.vue';

import { useRoute } from 'vue-router';
import Toast from './components/Toast.vue';
import { toastStore } from './stores/toast/Toast';
import { isEmpty } from 'lodash';
import { userStore } from '@/stores/authStore';
import { roleStore } from '@/stores/role';
import { profileStore } from '@/stores/profile';
import { regionStore } from '@/stores/regionStore';
import { projectStore } from '@/stores/project';
import { removeData } from '@/utils/AsyncService';

import { useMeta } from 'vue-meta';
import imgUrl from '@/assets/images/SEO_logo.svg';
import { $t } from '@/plugins/translation';

const isHeader = ref(false);
const type = ref('home');
const isLeft = ref(false);
const route = useRoute();
// const router = useRouter();

const authModule = userStore();
const roleModule = roleStore();
const profileModule = profileStore();
const projectModule = projectStore();
const storeToast = toastStore();
const isToast = ref(storeToast.toastData);
const region = regionStore();

const computedKey = computed(() => `${authModule.key}-${roleModule.isClient}`);

const showWarning = ref(true);

const isShowWarningSubscriptionBar = computed(
  () =>
    !['home', 'setting', 'Profile', 'Recruitment', 'instructionNewRegister', 'join'].includes(
      route.name as unknown as string,
    ) &&
    authModule.login &&
    showWarning.value &&
    projectModule.isWarningSubscription,
);

useMeta({
  title: $t('metadata', 'title'),
  meta: [
    { vmid: 'description', name: 'description', content: $t('metadata', 'description') },
    { vmid: 'keywords', name: 'keywords', content: $t('metadata', 'keywords') },
    { vmid: 'og:type', name: 'og:type', content: 'article' },
    { vmid: 'og:title', name: 'og:title', content: $t('metadata', 'title') },
    { vmid: 'og:description', name: 'og:description', content: $t('metadata', 'description') },
    { vmid: 'og:url', name: 'og:url', content: 'https://eposo.ai/' }, // 실제 URL
    { vmid: 'og:image', name: 'og:image', content: '/assets/images/SEO_logo.svg' }, // 실제 이미지 경로
    { vmid: 'og:image', name: 'og:image', content: imgUrl }, // 실제 이미지 경로
  ],
});

watchEffect(() => {
  if (isEmpty(route.meta)) return;
  isHeader.value = route.meta.isHeader == false ? false : true; // undefined 경우 true
  type.value = route.meta.type as string;
  isLeft.value = route.meta.isLeft == false ? false : true; // undefined 경우 true
  isToast.value = storeToast.toastData;
});

onMounted(() => {
  if (authModule.login) profileModule.getUserProfile();
  removeData('projectList');
  removeData('profile');
});

watch(
  () => authModule.login,
  async () => {
    await profileModule.getUserProfile();
    region.setLang();
  },
);

watch(
  () => projectModule.selectedProject.totalMember,
  () => {
    if (projectModule.isWarningSubscription) showWarning.value = true;
  },
);

// watch(
//   lang,
//   () => {
//     if(!lang.value) return;
//     console.log(lang.value)
//     const app = getCurrentInstance()?.appContext.app;
//     console.log(getCurrentInstance())

//     app?.runWithContext(() => {
//       const strings = STRINGS[lang.value];
//       console.log(strings)
//       useMeta({
//         title: strings.metadata.title,
//         meta: [
//           { vmid: 'description', name: 'description', content: strings.metadata.description },
//           { vmid: 'keywords', name: 'keywords', content: strings.metadata.keywords },
//           { vmid: 'og:type', name: 'og:type', content: 'article' },
//           { vmid: 'og:title', name: 'og:title', content: strings.metadata.title },
//           { vmid: 'og:description', name: 'og:description', content: strings.metadata.description },
//           { vmid: 'og:url', name: 'og:url', content: 'https://eposo.ai/' },  // 실제 URL
//           { vmid: 'og:image', name: 'og:image', content: '/assets/images/SEO_logo.svg' },  // 실제 이미지 경로
//           { vmid: 'og:image', name: 'og:image', content: imgUrl },  // 실제 이미지 경로
//         ]
//       });
//     });
//   },
//   { deep: true, immediate: true },
// );

// const handleDebouncedScroll = (event: any) => {
//   console.log(event)
//   console.log('handleDebouncedScroll');
// }

// onMounted(() => {
//   document.getElementById('content')!.addEventListener('scroll', handleDebouncedScroll);
// });
// onUnmounted(() => {
//   document.getElementById('content')!.removeEventListener('scroll', handleDebouncedScroll);
// })
</script>
<style lang="scss">
@import '@/assets/scss/tailwind.scss';
@import '@/assets/scss/style.scss';
</style>
